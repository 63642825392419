<template>
  <maps-yandex-maps-autocomplete
    v-if="appConfig.VueSettingsPreRun.MapsProvider === 'yandex'"
    :form="form"
  />
  <span
    v-else
    class="v-error-color"
    v-html="translate('mapsAddress.error')"
  />
</template>

<script setup lang="ts">
defineProps<{
  title?: string
  form: string
}>()

const appConfig = useAppConfig()
const { translate } = useI18nSanitized()
</script>
